import PropTypes from 'prop-types';
import styles from './node-content-renderer.module.scss';
import FontAwesome from "../../../utilities/FontAwesome";
import Button from "../../../utilities/Button";
import classNames from 'classnames';

function isDescendant(older, younger) {
    return (
        !!older.children &&
        typeof older.children !== 'function' &&
        older.children.some(
            child => child === younger || isDescendant(child, younger)
        )
    );
}

// eslint-disable-next-line react/prefer-stateless-function
const FileThemeNodeContentRenderer = (props) => {
    const {
        scaffoldBlockPxWidth,
        toggleChildrenVisibility,
        connectDragPreview,
        connectDragSource,
        isDragging,
        canDrop,
        canDrag,
        node,
        title,
        draggedNode,
        path,
        treeIndex,
        isSearchMatch,
        isSearchFocus,
        icons,
        buttons,
        className,
        style,
        didDrop,
        lowerSiblingCounts,
        listIndex,
        swapFrom,
        swapLength,
        swapDepth,
        treeId, // Not needed, but preserved for other renderers
        isOver, // Not needed, but preserved for other renderers
        parentNode, // Needed for dndManager
        rowDirection,
        ...otherProps
    } = props;

    const nodeTitle = title || node.title;

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    // Construct the scaffold representing the structure of the tree
    const scaffold = [];
    lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
        scaffold.push(
            <div
                key={`pre_${1 + i}`}
                style={{width: scaffoldBlockPxWidth}}
                className={styles.lineBlock}
            />
        );

        if (treeIndex !== listIndex && i === swapDepth) {
            // This row has been shifted, and is at the depth of
            // the line pointing to the new destination
            let highlightLineClass = '';

            if (listIndex === swapFrom + swapLength - 1) {
                // This block is on the bottom (target) line
                // This block points at the target block (where the row will go when released)
                highlightLineClass = styles.highlightBottomLeftCorner;
            } else if (treeIndex === swapFrom) {
                // This block is on the top (source) line
                highlightLineClass = styles.highlightTopLeftCorner;
            } else {
                // This block is between the bottom and top
                highlightLineClass = styles.highlightLineVertical;
            }

            scaffold.push(
                <div
                    key={`highlight_${1 + i}`}
                    style={{
                        width: scaffoldBlockPxWidth,
                        left: scaffoldBlockPxWidth * i,
                    }}
                    className={`${styles.absoluteLineBlock} ${highlightLineClass}`}
                />
            );
        }
    });

    let handle;
    if (canDrag) {
        if (typeof node.children === 'function' && node.expanded) {
            // Show a loading symbol on the handle when the children are expanded
            //  and yet still defined by a function (a callback to fetch the children)
            handle = (
                <div className={styles.loadingHandle}>
                    <div className={styles.loadingCircle}>
                        {[...new Array(12)].map((_, index) => (
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                // className={classnames(
                                //     'rst__loadingCirclePoint',
                                //     rowDirectionClass
                                // )}
                            />
                        ))}
                    </div>
                </div>
            );
        } else {
            // Show the handle used to initiate a drag-and-drop
            handle = connectDragSource(<div className={styles.moveHandle}><FontAwesome name={'fa-arrows-alt'}
                                                                                       prefix={'fas'}/></div>, {
                dropEffect: 'move',
            });
        }
    }

    const rowClassName = classNames({
        [styles.row]: true,
        [styles.rowLandingPad]: isLandingPadActive,
        [styles.rowCancelPad]: isLandingPadActive && !canDrop,
        [styles.rowSearchMatch]: isSearchMatch,
        [styles.rowSearchFocus]: isSearchFocus,
        [className]: className,
    })

    const buttonClassname = classNames({
        [styles.button]: true,
        [styles.buttonExpanded]: node.expanded,
        [styles.buttonCollapsed]: !node.expanded,
    })

    return <div style={{height: '100%'}} {...otherProps}>
        {toggleChildrenVisibility &&
        node.children &&
        node.children.length > 0 && (
            <Button
                type="button"
                aria-label={node.expanded ? 'Collapse' : 'Expand'}
                className={buttonClassname}
                style={{
                    left: (lowerSiblingCounts.length - 0.7) * scaffoldBlockPxWidth,
                }}
                onClick={() =>
                    toggleChildrenVisibility({
                        node,
                        path,
                        treeIndex,
                    })
                }
            >
                <FontAwesome ariaHidden={true} name={"fa-angle-right"} prefix={"fas"}/>
            </Button>
        )}

        <div
            className={
                styles.rowWrapper +
                (!canDrag ? ` ${styles.rowWrapperDragDisabled}` : '')
            }
        >
            {/* Set the row preview to be used during drag and drop */}
            {connectDragPreview(<div style={{display: 'flex'}}>
                {scaffold}
                <div
                    className={rowClassName}
                    style={{
                        opacity: isDraggedDescendant ? 0.5 : 1,
                        ...style,
                    }}
                >

                    <div
                        className={
                            (styles.rowContents) +
                            (!canDrag ? ` ${styles.rowContentsDragDisabled}` : '')
                        }
                    >
                        {handle}
                        <div className={styles.rowToolbar}>
                            {icons.map((icon, index) => (
                                <div
                                    key={index} // eslint-disable-line react/no-array-index-key
                                    className={styles.toolbarButton}
                                >
                                    {icon}
                                </div>
                            ))}
                        </div>

                        <div className={styles.rowLabel}>
                                        <span className={styles.rowTitle}>
                                          {typeof nodeTitle === 'function'
                                              ? nodeTitle({
                                                  node,
                                                  path,
                                                  treeIndex,
                                              })
                                              : nodeTitle}
                                        </span>
                        </div>

                        <div className={styles.rowToolbar}>
                            {buttons}
                        </div>
                    </div>
                </div>
            </div>)
            }
        </div>
    </div>;
}

FileThemeNodeContentRenderer.defaultProps = {
    buttons: [],
    canDrag: false,
    canDrop: false,
    className: '',
    draggedNode: null,
    icons: [],
    isSearchFocus: false,
    isSearchMatch: false,
    parentNode: null,
    style: {},
    swapDepth: null,
    swapFrom: null,
    swapLength: null,
    title: null,
    toggleChildrenVisibility: null,
};

FileThemeNodeContentRenderer.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.node),
    canDrag: PropTypes.bool,
    className: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.node),
    isSearchFocus: PropTypes.bool,
    isSearchMatch: PropTypes.bool,
    listIndex: PropTypes.number.isRequired,
    lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
    node: PropTypes.shape({}).isRequired,
    path: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    style: PropTypes.shape({}),
    swapDepth: PropTypes.number,
    swapFrom: PropTypes.number,
    swapLength: PropTypes.number,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    toggleChildrenVisibility: PropTypes.func,
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,
    rowDirection: PropTypes.string.isRequired,

    // Drag and drop API functions
    // Drag source
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    didDrop: PropTypes.bool.isRequired,
    draggedNode: PropTypes.shape({}),
    isDragging: PropTypes.bool.isRequired,
    parentNode: PropTypes.shape({}), // Needed for dndManager
    // Drop target
    canDrop: PropTypes.bool,
    isOver: PropTypes.bool.isRequired,
};

export default FileThemeNodeContentRenderer;
